import useWindowSize from "./useWindowSize";

const TABLET_MAX = 1279
const MOBILE_MAX = 768

function useWindowBreakpoint() {
    const { width } = useWindowSize()

    let breakpoint
    if (width != null) {
        if (width >= TABLET_MAX) {
            breakpoint = 'pc'
        } else if (width >= MOBILE_MAX) {
            breakpoint = 'tablet'
        } else {
            breakpoint = 'mobile'
        }
    } else {
        breakpoint = 'pc'
    }
    
    return { breakpoint }
    
}

export default useWindowBreakpoint
