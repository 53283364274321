import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'
import BlockSmall from '../common/BlockSmall'

import HighlightText from '../common/HighlightText'
import RightArrow from '../../assets/icons/arrow-right.svg'
import { modifyLangString } from '../../lib/utils/modifyLangString'



function ProductsSecondSection() {
    const { t, i18n } = useTranslation()
    const currentLanguage = modifyLangString(i18n.language)
    
	return (
        <BlockSmall className="container">
            <Content>
                <TitleContainer language={currentLanguage}>
                    <Typography.Display size='xlarge' fontWeight='bold'>
                        {t('ProductsSecondSection.heading')}
                    </Typography.Display>
                </TitleContainer>
                <Item>
                    <ItemLeft>
                    <HeadingText language={currentLanguage}>
                        <Typography.Heading size='xxlarge' fontWeight='bold'>
                            {t('ProductsSecondSection.p1t')}
                        </Typography.Heading>
                    </HeadingText>
                    <SubText language={currentLanguage}>
                        <Typography.Heading size='small' fontWeight='bold'>
                            <HighlightText text={t('ProductsSecondSection.p1s')} />
                        </Typography.Heading>
                    </SubText>
                    </ItemLeft>
                    <ItemRight>
                        <ItemRightItem>
                            <ItemRightItemHeading href='/products#scribe' language={currentLanguage}>
                                <Typography.Body size='xlarge' fontWeight='bold'>
                                    {t('ProductsSecondSection.p1i1t')}
                                    <RightArrow color='black' style={{ paddingLeft: 10}}/>
                                </Typography.Body>
                            </ItemRightItemHeading>
                            <ItemRightItemDesc language={currentLanguage}>
                                <Typography.Body size='large'>
                                    {t('ProductsSecondSection.p1i1s')}
                                </Typography.Body>
                            </ItemRightItemDesc>
                        </ItemRightItem>
                        <ItemRightItem>
                          <ItemRightItemHeading language={currentLanguage}>
                                <Typography.Body size='xlarge' fontWeight='bold'>
                                    {t('ProductsSecondSection.p1i2t')}
                                    <RightArrow color='black' style={{ paddingLeft: 10}}/>
                                </Typography.Body>
                            </ItemRightItemHeading>
                            <ItemRightItemDesc language={currentLanguage}>
                                <Typography.Body size='large'>
                                    {t('ProductsSecondSection.p1i2s')}
                                </Typography.Body>
                            </ItemRightItemDesc>
                        </ItemRightItem>
                    </ItemRight>
                </Item>
                <Item>
                    <ItemLeft>
                    <HeadingText language={currentLanguage}>
                        <Typography.Heading size='xlarge' fontWeight='bold'>
                            {t('ProductsSecondSection.p2t')}
                        </Typography.Heading>
                    </HeadingText>
                    <SubText language={currentLanguage}>
                        <Typography.Heading size='small' fontWeight='bold'>
                            <HighlightText text={t('ProductsSecondSection.p2s')} />
                        </Typography.Heading>
                    </SubText>
                    </ItemLeft>
                    <ItemRight>
                        <ItemRightItem>
                            <ItemRightItemHeading language={currentLanguage}>
                            <Typography.Body size='xlarge' fontWeight='bold'>
                                {t('ProductsSecondSection.p2i1t')}
                                <SoonSign>{t('ProductsSecondSection.soonSign')}</SoonSign>
                            </Typography.Body>
                            </ItemRightItemHeading>
                            <ItemRightItemDesc language={currentLanguage}>
                            <Typography.Body size='large'>
                                {t('ProductsSecondSection.p2i1s')}
                            </Typography.Body>
                            </ItemRightItemDesc>
                        </ItemRightItem>
                    </ItemRight>
                </Item>
            </Content>
        </BlockSmall>
	)
}

const TitleContainer = styled.div`
    padding-top: 64px;
    margin-bottom: 180px;

    @media (max-width: 1279px) {
        span {
            font-size: 65px;
            margin-bottom: 80px;
        }
    }

    @media (max-width: 768px) {
        padding-top: 20px;
        margin-bottom: 78px;
        span {
            font-size: ${props => props.language === 'en' ? '35px': '30px'};
            line-height: 50px !important;
        }
    }
`

const Content = styled.div`
    margin-top: 178px;
	position: relative;

    @media (max-width: 768px) {
        margin-top: 60px;
    }
`

const HeadingText = styled.div`
    margin-bottom: 10px;
    h1 {
        ${props => props.language === 'en' ? 'font-size: 40px;': ''}
    }

    @media (max-width: 768px) {
        margin-bottom: 8px;
    }
`

const SubText = styled.div`
    margin-bottom:64px;
    b {
        color: #b8b8b8;
    }

    h5 {
        font-size: ${props => props.language === 'en' ? '25px;': '28px;'}
    }

    @media (max-width: 768px) {
        margin-bottom: 50px;
    }
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 130px;

    @media (max-width: 1279px) {
        flex-direction: column;
    }

    @media (max-width: 768px) {
        margin-bottom: 60px;
    }
`

const ItemLeft = styled.div`
    width: 530px;
    margin-right: 100px;


    @media (max-width: 768px) {
        width: auto;
        margin-right: 0px;
        h1 {
            font-size: 25px;
        }

        h5 {
            margin-top: 20px;
            font-size: 17px;
        }
    }
`

const ItemRight = styled.div`
    width: 320px;

    @media (max-width: 1279px) {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 38px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0px;
    }
`

const ItemRightItem = styled.div`
    margin-bottom: 50px;

    @media (max-width: 1279px) {
        width: 328px;
    }
`

const ItemRightItemHeading = styled.a`
    margin-bottom: 12px;
    cursor: pointer;
    display:block;

    p {
        ${props => props.language === 'en' ? 'font-size: 19px;': ''}
        display: flex;
        align-items: center;
    }
    `

const ItemRightItemDesc = styled.div`
    p {
        ${props => props.language === 'en' ? 'font-size: 18px;': ''}
    }
    @media (max-width: 768px) {
        p {
            font-size: 16px;
            line-height: 28px;
        }
    }

`

const SoonSign = styled.span`
    display: inline-block; 
    font-size: 14px;
    color: #bbb;
    font-weight: 500;
    padding-left: 24px;
`
export default ProductsSecondSection
