import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { down, only, up } from 'styled-breakpoints'

import SEO from '../components/base/SEO'
import Layout from '../components/base/Layout'
import MedicalIntroSection from '../components/products/MedicalIntroSection'
import MedicalSecondSection from '../components/products/MedicalSecondSection'
import MedicalThirdSection from '../components/products/MedicalThirdSection'
import MedicalCTASection from '../components/products/MedicalCTASection'

import InvertColorsContext from '../context/InvertColorsContext'
import useWindowSize from '../lib/hooks/useWindowSize'
import MedicalFourthSection from '../components/products/MedicalFourthSection'
import MedicalFifthSection from '../components/products/MedicalFifthSection'
import MedicalSixthSection from '../components/products/MedicalSixthSection'
import MedicalSeventhSection from '../components/products/MedicalSeventhSection'
import ProductsFirstSection from '../components/products/ProductsFirstSection'
import ProductsSecondSection from '../components/products/ProductsSecondSection'
import ProductsThirdSection from '../components/products/ProductThirdSection'
import ProductsFourthSection from '../components/products/ProductFourthSection'

const metaData = {
	title: 'Products | TESSER',
	description:
		'병원 업무와 진료환경을 더 편리하게 바꿔주고, 환자의 만족도와 치료여정을 돕는 AI 서비스를 제공합니다. ',
	keywords:
		'온톨, 병원, AI, LLM, AI Agent, 의료 AI, 건강검진센터, 검진 AI, 검사결과지 해석, 환자 커뮤니티, 의료영상, 의료데이터, 임상데이터, 의무기록지 해석, 검사결과 설명, 의료 언어모델, 의료 LLM, 헬스케어 데이터, 환자 데이터, 환자 서비스, 온톨 앱',
}

function Medical() {
	const windowSize = useWindowSize()
	const [innerHeight, setInnerHeight] = useState<number | null>(null)

	useEffect(() => {
		if (windowSize.height) {
			setInnerHeight(windowSize.height)
		}
	}, [windowSize])

	useEffect(() => {
		const onRouteUpdate = () => {
			setTimeout(() => {
				const location = window.location

				if (location.pathname.startsWith('/product') === true && location.hash) {
					const id = location.hash.replace('#', '')
					const element = document.getElementById(id)

					if (element) {
						element.scrollIntoView({ block: 'start' })
					}
				}
			}, 500)
		}

		onRouteUpdate()
	})

	if (!innerHeight) {
		return (
			<Layout color="black" blackFooter medicalPage>
				<SEO metaData={metaData} />
				<Block></Block>
			</Layout>
		)
	}

	return (
		<Layout color="black" blackFooter medicalPage>
			<SEO metaData={metaData} />
			<InvertColorsContext.Consumer>
				{({ invertColors }) => (
					<Block>
						{/* <MedicalIntroSection
							innerHeight={innerHeight}
							invertColors={invertColors}
						/> */}
						<ProductsFirstSection />
						<ProductsSecondSection />
						<ProductsThirdSection />
						<ProductsFourthSection />
						<MedicalSecondSection />
						<MedicalThirdSection />
						<MedicalFourthSection />
						{/* <MedicalFifthSection />
						<MedicalSixthSection />
						<MedicalSeventhSection /> */}
						<MedicalCTASection/>
					</Block>
				)}
			</InvertColorsContext.Consumer>
		</Layout>
	)
}

const Block = styled.div`
	min-height: 100vh;
	margin: 0 auto;

	> *:not(:last-child) {
		${up('xl')} {
			margin-bottom: 160px;
		}
		${only('lg')} {
			margin-bottom: 160px;
		}
		${only('md')} {
			margin-bottom: 160px;
		}
		${only('sm')} {
			margin-bottom: 160px;
		}
		${down('xs')} {
			margin-bottom: 120px;
		}
	}
`

export default Medical
