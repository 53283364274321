import styled from 'styled-components'

const BlockSmall = styled.div`
	margin: 0 auto;
	position: relative;

	@media (min-width: 1279px) {
		width: 1008px;
	}
	@media (min-width: 768px) and (max-width: 1279px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

export default BlockSmall