import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'
import GradientCanvas from '../main/GradientCanvas'

import BlockSmall from '../common/BlockSmall'
import Logo from '../../assets/images/solution/logo.svg'
import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'
import ButtonWithIcon from '../common/ButtonWithIcon'
import useWindowSize from '../../lib/hooks/useWindowSize'
import useWindowBreakpoint from '../../lib/hooks/useWindowBreakpoint'

const gradientColors = {
    color1: '#00F3AA ',
    color2: '#0094FF',
    color3: '#1D41FF',
    color4: '#1AFFD6',
}

function ProductsFirstSection() {
    const data = useStaticQuery(graphql`
        query {
            OntolCharacter: file(
                relativePath: { eq: "images/medical/ontol.png" }
            ) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    const { breakpoint } = useWindowBreakpoint()
	const { t } = useTranslation()
    
    //for PC
    let characterWidth = 332
    let characterHeight = 386
    let characterTop = 60
    let logoWidth = 565
    let logoHeight = 160
    let subText = t('ProductsFirstSection.subtext')
    let buttonFontSize = '20px'

    if (breakpoint === 'tablet') {
        characterWidth = 228
        characterHeight = 270
        characterTop = 60
        logoWidth = 449
        logoHeight = 140
    } else if (breakpoint === 'mobile') {
        characterWidth = 120
        characterHeight = 150
        characterTop = 350
        logoWidth = 240
        logoHeight = 50
        subText = t('ProductsFirstSection.subtextMobile')
        buttonFontSize = '16px'
    } 

	return (
        <BlockSmall className="container">
            <Content>
                <Image data={{ placeholderImage: data.OntolCharacter}} styles={{
                    width: characterWidth, 
                    height: characterHeight,
                    position: 'absolute',
                    right: 0,
                    top: characterTop,
                    zIndex:30
                }}/>
                <TitleContainer>
                    <Logo width={logoWidth} height={logoHeight}/>
                </TitleContainer>
                <HeadingText>
                    <Typography.Heading size='xxlarge' fontWeight='bold'>
                        {t('ProductsFirstSection.heading')}
                    </Typography.Heading>
                </HeadingText>
                <GradientBlock className="gradientBox">
                    <GradientCanvas gradientColors={gradientColors} />
                </GradientBlock>
                <SubText>
                    <Typography.Body size='xlarge'>
                        {subText}
                    </Typography.Body>
                </SubText>
                <ButtonWithIcon className="cta" fontSize={buttonFontSize}>
					{t('ProductsFirstSection.button')}
				</ButtonWithIcon>
            </Content>
        </BlockSmall>
	)
}

const TitleContainer = styled.div`
    padding-top: 64px;
`

const GradientBlock = styled.div`
	width: 100%;
	height: 386px;
	overflow: hidden;
	display: flex;
	align-items: center;
	animation: fadeIn 0.8s;

    @media (max-width: 768px) {
        height: 200px;
    }
`

const Content = styled.div`
    margin-top: 178px;
	position: relative;

    @media (max-width: 768px) {
        margin-top: 30px;
        h1 {
            font-size: 28px;
            line-height: 32px;
        }
    }
`

const HeadingText = styled.div`
    margin-top: 10px;
    margin-bottom: 64px;

    @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom:18px;
    }
`

const SubText = styled.div`
    margin-top: 80px;
    margin-bottom:64px;
`
export default ProductsFirstSection
