import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { buttonHover } from '../../lib/styles/utils'

function MedicalCTASection() {
	const { t } = useTranslation()

	return (
		<FullWidth>
			<Block>
				<HeadingContainer>
					<HeadingTitle>
						{t('MedicalCTASection.headingTitle')}
					</HeadingTitle>
					<HeadingDescription>
						{t('MedicalCTASection.headingDescription')}
					</HeadingDescription>
				</HeadingContainer>
				<ButtonContainer>
					<MoreButton href="/contact">
						<MoreButtonText>
							{t('MedicalCTASection.button')}
						</MoreButtonText>
					</MoreButton>
				</ButtonContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	@media (min-width: 1440px) {
		width: 1008px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 1440px) {
		padding-top: 272px;
		gap: 10px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 226px;
		gap: 24px;
	}
	@media (max-width: 768px) {
		padding-top: 192px;
		gap: 24px;
	}
`

const HeadingTitle = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 80px;
		line-height: 96px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 64px;
		line-height: 72px; /* 112.5% */
	}
	@media (max-width: 768px) {
		font-size: 40px;
		line-height: 48px; /* 120% */
		text-align: center;
	}
`

const HeadingDescription = styled.div`
	
	text-align: center;
	white-space: pre-line;

	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 140% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 133.333% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
		white-space: pre-line;
	}
`

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	padding-top: 64px;

	@media (min-width: 1440px) {
		justify-content: center;
		padding-bottom: 360px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		justify-content: center;
		padding-bottom: 240px;
	}
	@media (max-width: 768px) {
		justify-content: center;
		padding-bottom: 165px;
	}
`

const MoreButton = styled.a`
	cursor: pointer;
	display: flex;
	padding: 16px 67px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	background-color: black;
	border-radius: 80px;
	width: max-content;

	${buttonHover}
`

const MoreButtonText = styled.div`
	line-height: 28px; /* 140% */
	color: white;

	@media (min-width: 1440px) {
		font-size: 16px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 14px;
	}
	@media (max-width: 768px) {
		font-size: 12px;
	}
`

export default MedicalCTASection
