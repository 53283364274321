import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'
import Arrow from '../../assets/icons/chevron.svg'
import { modifyLangString } from '../../lib/utils/modifyLangString'

import LogoOfP from '../../assets/svg/LogoOfP.svg'


function MedicalSecondSection() {
	const { t, i18n } = useTranslation()
	const currentLanguage = modifyLangString(i18n.language)

	const data = useStaticQuery(graphql`
		query {
			Iphoneimage1: file(
				relativePath: { eq: "images/medical/IphoneImage1.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Iphoneimage1_en: file(
				relativePath: { eq: "images/medical/IphoneImage1_en.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Iphoneimage2: file(
				relativePath: { eq: "images/medical/IphoneImage2.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Iphoneimage2_en: file(
				relativePath: { eq: "images/medical/IphoneImage2_en.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Shadows: file(relativePath: { eq: "images/medical/Shadows.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth id="ontol">
			<Block>
				<TitleContainer>
					<TitleMainAndBeta>
						<LogoOfP/>
					</TitleMainAndBeta>
					<TitleSub1>{t('MedicalSecondSection.3')}</TitleSub1>
				</TitleContainer>
				<ImageContainer>
					<Image1Wrapper>
						<ShadowsWrapper>
							<Image data={{ placeholderImage: data.Shadows }} />
						</ShadowsWrapper>
						<Image
							data={
								currentLanguage === 'ko'
									? { placeholderImage: data.Iphoneimage1 }
									: { placeholderImage: data.Iphoneimage1_en }
							}
						/>
					</Image1Wrapper>
					<Image2Wrapper>
						<ShadowsWrapper>
							<Image data={{ placeholderImage: data.Shadows }} />
						</ShadowsWrapper>
						<Image
							data={
								currentLanguage === 'ko'
									? { placeholderImage: data.Iphoneimage2 }
									: { placeholderImage: data.Iphoneimage2_en }
							}
						/>
					</Image2Wrapper>
				</ImageContainer>
				<TitleSub2Container>
					<TitleSub2Text>{t('MedicalSecondSection.4')}</TitleSub2Text>
				</TitleSub2Container>
				<DescriptionContainer>
					<DescriptionTextAndButtonBox>
						<DescriptionText>{t('MedicalSecondSection.5')}</DescriptionText>
						<MoreButton href="https://www.ontol.com" target="_blank">
							<MoreButtonText>{t('MedicalSecondSection.6')}</MoreButtonText>
							<Arrow />
						</MoreButton>
					</DescriptionTextAndButtonBox>
				</DescriptionContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	width: 100%;
`

const Block = styled.div`
	position: relative;

	@media (min-width: 1440px) {
		width: 1008px;
		margin-top: 180px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		margin-top: 180px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const TitleMainAndBeta = styled.div`
	display: flex;
	align-items: flex-end;
`

const TitleSub1 = styled.div`
	font-weight: 700;
	white-space: pre-wrap;

	@media (min-width: 1440px) {
		margin-top: 50px;
		font-size: 56px;
		line-height: 64px; /* 114.286% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		margin-top: 30px;
		margin-bottom: 50px;
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		margin-top: 20px;
		font-size: 24px;
		line-height: 32px; /* 133.333% */
	}
`

const ImageContainer = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		gap: 96px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 64px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 32px;
		padding-top: 32px;
	}
`

const Image1Wrapper = styled.div`
	position: relative;

	@media (min-width: 1440px) {
		width: 456px;
		margin-top: 96px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 312px;
		margin-top: 96px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const Image2Wrapper = styled.div`
	position: relative;

	@media (min-width: 1440px) {
		width: 456px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 312px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const ShadowsWrapper = styled.div`
	position: absolute;

	@media (min-width: 1440px) {
		width: 796px;
		top: -100px;
		left: -260px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 532px;
		top: -55px;
		left: -180px;
	}
	@media (max-width: 768px) {
		width: 530px;
		top: -60px;
		left: -158px;
	}
`

const TitleSub2Container = styled.div`
	display: flex;
	width: 100%;

	@media (min-width: 1440px) {
		justify-content: flex-end;
		padding-top: 72px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		justify-content: flex-end;
		padding-top: 72px;
	}
	@media (max-width: 768px) {
		justify-content: flex-start;
		padding-top: 47px;
	}
`

const TitleSub2Text = styled.div`
	font-weight: 700;
	white-space: pre-wrap;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 72px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px; /* 122.222% */
	}
`

const DescriptionContainer = styled.div`
	display: flex;
	width: 100%;

	@media (min-width: 1440px) {
		justify-content: flex-end;
		padding-top: 172px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		justify-content: flex-start;
		padding-top: 136px;
	}
	@media (max-width: 768px) {
		justify-content: flex-start;
		padding-top: 112px;
	}
`

const DescriptionTextAndButtonBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const DescriptionText = styled.div`
	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
		white-space: pre-line;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
		white-space: pre-wrap;
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
`

const MoreButton = styled.a`
	cursor: pointer;
	display: flex;
	padding: 16px 24px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	background-color: black;
	border-radius: 80px;
	width: max-content;
`

const MoreButtonText = styled.div`
	line-height: 132%; /* 21.12px */
	color: white;

	@media (min-width: 1440px) {
		font-size: 16px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 14px;
	}
	@media (max-width: 768px) {
		font-size: 12px;
	}
`

export default MedicalSecondSection
