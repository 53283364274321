import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'

function MedicalFourthSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			SectionImage: file(
				relativePath: { eq: "images/medical/ProductSection4.jpg" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth>
			<Block>
				<HeadingContainer>
					<Title>{t('MedicalFourthSection.1')}</Title>
					<Description>{t('MedicalFourthSection.2')}</Description>
				</HeadingContainer>
				<ContentsContainer>
					<IndicatorAndCreatedAtContainer>
						<IndicatorContainer>
							<IndicatorBox>
								<IndicatorName>{t('MedicalFourthSection.3')}</IndicatorName>
								<IndicatorNumber>{t('MedicalFourthSection.4')}</IndicatorNumber>
							</IndicatorBox>
							<IndicatorBox>
								<IndicatorName>{t('MedicalFourthSection.5')}</IndicatorName>
								<IndicatorNumber>{t('MedicalFourthSection.6')}</IndicatorNumber>
							</IndicatorBox>
						</IndicatorContainer>
						<CreatedAt>{t('MedicalFourthSection.7')}</CreatedAt>
					</IndicatorAndCreatedAtContainer>
				</ContentsContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	position: relative;

	@media (min-width: 1440px) {
		width: 1008px;
		margin-top: 200px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		margin-top: 150px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const Title = styled.div`
	font-weight: 700;
	@media (min-width: 1440px) {
		font-size: 50px;
		line-height: 72px; /* 112.5% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 40px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px; /* 122.222% */
		white-space: pre-wrap;
	}
`

const Description = styled.div`
	font-weight: 400;

	@media (min-width: 1440px) {
		font-size: 24px;
		line-height: 32px; /* 133.333% */
		padding: 53px 0px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
		padding: 48px 0px;
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
		padding: 32px 0px;
	}
`

const ContentsContainer = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: column;
		justify-content: space-between;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 32px;
	}
`

const IndicatorAndCreatedAtContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		gap: 10px;
		padding-bottom: 80px;
		margin-top: 50px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 24px;
		padding-bottom: 60px;
	}
	@media (max-width: 768px) {
		gap: 13px;
	}
`

const IndicatorContainer = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row;
		gap: 72px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: row;
		gap: 72px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 32px;
	}
`

const IndicatorBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const IndicatorName = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 24px;
		line-height: 36px; /* 128.571% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 24px;
		line-height: 36px; /* 128.571% */
	}
	@media (max-width: 768px) {
		font-size: 24px;
		line-height: 32px; /* 133.333% */
	}
`

const IndicatorNumber = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 72px;
		line-height: 88px; /* 122.222% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 64px;
		line-height: 88px; /* 122.222% */
	}
	@media (max-width: 768px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
`

const CreatedAt = styled.div`
	font-weight: 400;
	color: #aaa;
	font-size: 14px;
	line-height: 20px; /* 155.556% */
`

const ImageWrapper = styled.div`
	@media (min-width: 1440px) {
		width: 100%;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 317px;
	}
`

export default MedicalFourthSection
