import React from 'react'
interface HighlightTextProps {
  text: string
}

const HighlightText = ({ text }:HighlightTextProps) => {
  // const s = "[[검진 실무는]] 간편하게, [[수검자 만족도는]] 높게";

  // This regex matches text between double brackets.
  const regex = /\[\[(.*?)\]\]/g;

  // We'll keep track of the last index processed to handle text outside the brackets.
  const result = [];
  let lastIndex = 0;
  let match;
  let key = 0;

  // Use regex.exec in a loop to extract the matches.
  while ((match = regex.exec(text)) !== null) {
    // Add text before the match, if any.
    if (match.index > lastIndex) {
      result.push(text.slice(lastIndex, match.index));
    }
    // Add the matched text as a bold element.
    result.push((<b key={key++}>{match[1]}</b>));
    lastIndex = regex.lastIndex;
  }

  // Add any remaining text after the last match.
  if (lastIndex < text.length) {
    result.push(text.slice(lastIndex));
  }

  return <div>{result}</div>;
};

export default HighlightText;