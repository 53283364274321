import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'

import Logo from '../../assets/svg/LogoScribe.svg'
import Scribe from '../../assets/images/medical/scribe.svg'
import ScribeMobile from '../../assets/images/medical/ScribeMobile.svg'
import ScribeEn from '../../assets/images/medical/scribe_en.svg'
import ScribeMobileEn from '../../assets/images/medical/ScribeMobile_en.svg'
import BlockSmall from '../common/BlockSmall'
import ButtonWithIcon from '../common/ButtonWithIcon'
import LinkWrapper from '../common/LinkWrapper'
import useWindowBreakpoint from '../../lib/hooks/useWindowBreakpoint'
import { modifyLangString } from '../../lib/utils/modifyLangString'



function ProductsThirdSection() {
    const { t, i18n } = useTranslation()
    const currentLanguage = modifyLangString(i18n.language)

    const { breakpoint } = useWindowBreakpoint()

    //for PC
    let logoWidth = 747
    let logoHeight = 160
    let subText = t('ProductsThirdSection.subText')

    if (breakpoint === 'tablet') {
        logoWidth = 588
        logoHeight = 140
        subText = t('ProductsThirdSection.subTextTablet')
    } else if (breakpoint === 'mobile') {
        logoWidth = 240
        logoHeight = 70
        subText = t('ProductsThirdSection.subTextMobile')
    } 

	return (
        <BlockSmall className="container" id='scribe'>
            <Content>
                <Header>
                    <TitleContainer>
                        <Logo width={logoWidth} height={logoHeight}/>
                    </TitleContainer>
                    <HeadingText>
                        <Typography.Display size='large' fontWeight='bold'>
                            {t('ProductsThirdSection.heading')}
                        </Typography.Display>
                    </HeadingText>
                </Header>
                <ImageContainer>
                    {breakpoint !== 'mobile' ? 
                        currentLanguage === 'ko' ? <Scribe /> : <ScribeEn />
                        : currentLanguage === 'ko' ? <ScribeMobile /> : <ScribeMobileEn />}
                </ImageContainer>
                <SubText>
                    <Typography.Body size='xlarge'>
                        {subText}
                    </Typography.Body>
                </SubText>
                <Items>
                    <ItemRow>
                        <Item>
                            <ItemTitle  language={currentLanguage}>
                                <Typography.Heading size='medium' fontWeight='bold'>
                                    {t('ProductsThirdSection.i1t')}
                                </Typography.Heading>
                            </ItemTitle>
                            <ItemDesc>
                                <Typography.Body size='medium'>
                                    {t('ProductsThirdSection.i1s')}
                                </Typography.Body>
                            </ItemDesc>
                        </Item>
                        <Item>
                            <ItemTitle  language={currentLanguage}>
                                <Typography.Heading size='medium' fontWeight='bold'>
                                    {t('ProductsThirdSection.i2t')}
                                </Typography.Heading>
                            </ItemTitle>
                            <ItemDesc>
                                <Typography.Body size='medium'>
                                    {t('ProductsThirdSection.i2s')}
                                </Typography.Body>
                            </ItemDesc>
                        </Item>
                    </ItemRow>
                    <ItemRow>
                        <Item>
                            <ItemTitle  language={currentLanguage}>
                                <Typography.Heading size='medium' fontWeight='bold'>
                                    {t('ProductsThirdSection.i3t')}
                                </Typography.Heading>
                            </ItemTitle>
                            <ItemDesc>
                                <Typography.Body size='medium'>
                                    {t('ProductsThirdSection.i3s')}
                                </Typography.Body>
                                <ItemRef>
                                    {t('ProductsThirdSection.i3x')}
                                </ItemRef>
                            </ItemDesc>
                        </Item>
                        <Item>
                            <ItemTitle language={currentLanguage}>
                                <Typography.Heading size='medium' fontWeight='bold'>
                                    {t('ProductsThirdSection.i4t')}
                                </Typography.Heading>
                            </ItemTitle>
                            <ItemDesc>
                                <Typography.Body size='medium'>
                                    {t('ProductsThirdSection.i4s')}
                                </Typography.Body>
                                <ItemRef>
                                    {t('ProductsThirdSection.i4x')}
                                </ItemRef>
                            </ItemDesc>
                        </Item>
                    </ItemRow>
                </Items>
                <Buttons  language={currentLanguage}>
                    <LinkWrapper to='https://blog.tesser.io/ontol-scribe/' target='_blank'>
                        <ButtonWithIcon >
                            {t('ProductsThirdSection.learnMore')}
                        </ButtonWithIcon>
                    </LinkWrapper>
                    <LinkWrapper to='/contact'>
                        <ButtonWithIcon>
                            {t('ProductsThirdSection.contact')}
                        </ButtonWithIcon>
                    </LinkWrapper>
                </Buttons>
            </Content>
        </BlockSmall>
	)
}

const TitleContainer = styled.div`
    padding-top: 64px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        padding-top: 0px;
        margin-bottom: 5px;
    }
`

const Content = styled.div`
    margin-top: 178px;
	position: relative;
    @media (max-width: 768px) {
        margin-top: 0px;
    }
`

const HeadingText = styled.div`
    margin-bottom: 10px;
    @media (max-width: 1279px) {
        font-size: 50px;
        line-height: 70px;
    }

    @media (max-width: 768px) {
        line-height: 24px;
        span { 
            font-size: 28px;
            line-height: 36px;
        }
    }
`

const Header = styled.div`
    /* padding: 0px 102px; */
`
const ImageContainer = styled.div`
    padding: 10px;
    margin-top: 70px;

    svg {
        display: block; /* Prevents inline spacing issues */
        width: 100%;
        height: auto;
    }

    @media (max-width: 768px) {
        padding: 0px;
        margin-top: 20px;
    }
`

const SubText = styled.div`
    margin-top: 170px;
    margin-bottom: 120px;

    @media (max-width: 1279px) {
        margin-top: 120px;
        margin-bottom: 72px;
        span {
            font-size: 20px;
            line-height: 32px;
        }
    }

    @media (max-width: 768px) {
        margin-top: 50px;
        span {
            font-size: 18px;
            line-height: 28px;
        }
    }
`

const Items = styled.div`
    
`

const ItemRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 70px;
    height: 180px;
    gap: 136px;
    @media (max-width: 1279px) {
        gap: 32px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        gap: 0px;
        margin-bottom: 0px;
    }
`

const Item = styled.div`
    width: 382px;
    @media (max-width: 1279px) {
        width: 328px;
    }

`

const ItemTitle = styled.div`
    margin-bottom: 12px;
    display:block;
    
    h4 {
        font-size: ${props => props.language === 'en' ? '23px': '30px'};
    }
    @media (max-width: 1279px) {
        h4 {
            ${props => props.language === 'en' ? 'font-size: 20px;': ''}
        }
    }
    @media (max-width: 768px) {
        h4 {
            font-size: 18px;
        }
    }
`

const ItemDesc = styled.div`
    @media (max-width: 1279px) {
        span {
            font-size: 16px;
            line-height: 30px;
        }
    }

    @media (max-width: 768px) {
        span {
            line-height: 24px;
        }
        margin-bottom: 50px;
    }

`

const ItemRef = styled.span`
    display: inline-block; 
    font-size: 16px;
    color: #bbb;
    font-weight: 400;
    padding-top: 18px;

    @media (max-width: 768px) {
        font-size: 14px !important;
    }
`

const Buttons = styled.div`
    margin-top: ${props => props.language === 'en' ? '20px;': '-40px'};
    display: flex;
    flex-direction: row;
    gap: 32px;
    
    @media (max-width: 1279px) {
        margin-top: ${props => props.language === 'en' ? '100px;': '0px'};
        gap: 16px;
    }
    
    @media (max-width: 768px) {
        margin-top: 0px}
    }
`

export default ProductsThirdSection
